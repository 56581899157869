import '../../../../../src/features/configurations/societe/Societe.scss'
import '../../../../../src/features/stock/attendu/Attendu.scss'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { Col, Modal, notification, Row, Spin } from 'antd'
import { t } from 'i18next'
import { cloneDeep, isEmpty } from 'lodash'
import moment from 'moment'
import { ReactNode, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { RootState } from '../../../../app/store'
import CLOSE_ICON from '../../../../assets/images/close-success.png'
import ERROR from '../../../../assets/images/error.png'
import SUCCESS from '../../../../assets/images/success.png'
import CustomPopup from '../../../../components/Common/CustomPopup/CustomPopup'
import Define from '../../../../constants/define'
import { ActionOnReference } from '../../../../enum/ActionOnReference'
import { MODE } from '../../../../enum/mode'
import { popupType } from '../../../../enum/popupType'
import { Unit } from '../../../../enum/Unit'
import referenceApi, {
  UpdateReferenceRequest,
} from '../../../../http/referenceApi'
import {
  Conditionnement,
  ReferenceRequest,
  RefFournisser,
} from '../../../../models'
import { CommonError } from '../../../../models/common/error'
import { getAllReference } from '../../../../redux/reducers/referenceSlice'
import { randomID } from '../../../../utils'
import Commentaire, {
  FormCommentaireStateInterface,
} from '../../../stock/stockscreen/component/Commentaire/Commentaire'
import Fournisseurs from '../../../stock/stockscreen/component/Fournisseurs/Fournisseurs'
import Gestion, {
  FormGestionStateInterface,
} from '../../../stock/stockscreen/component/Gestion/Gestion'
import Identification, {
  FormStateInterface,
} from '../../../stock/stockscreen/component/Identification/Identification'
import { DataTableParam } from '../../../stock/stockscreen/component/model'
import Packing from '../../../stock/stockscreen/Packing'

interface RefEditProps {
  mode: MODE
  action?: ActionOnReference
}

export default function RefEdit(props: RefEditProps) {
  const stockKey = useSelector((state: RootState) => state.selector).data.stock
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const params = useParams()
  const [searchParams] = useSearchParams()
  const [identificationData, setIdentificationData] =
    useState<FormStateInterface>()
  const [commentaireData, setCommentaireData] =
    useState<FormCommentaireStateInterface>()
  const [gestionData, setGestionData] = useState<FormGestionStateInterface>()
  const [packingData, setPackingData] = useState<DataTableParam[][]>()
  const [packingDataFromApi, setPackingDataFromApi] = useState<
    DataTableParam[][]
  >([])
  const [fournisseursData, setFournisseursData] = useState<DataTableParam[][]>()
  const [errorIdentification, setErrorIdentification] = useState<CommonError[]>(
    []
  )
  const [errorGestion, setErrorGestion] = useState<CommonError[]>([])

  const [isValid_identification, set_isValid_identification] =
    useState<boolean>(false)
  const [isValid_commentaire] = useState<boolean>(true)
  const [isValid_gestion, set_isValid_gestion] = useState<boolean>(true)
  const [isValid_packing, set_isValid_packing] = useState<boolean>(false)
  const [isValid_fournisseurs, set_isValid_fournisseurs] =
    useState<boolean>(false)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [numberOfSubmit, setNumberOfSubmit] = useState<number>(0)
  const [isShowAlertPacking, setIsShowAlertPacking] = useState<boolean>(false)
  const [isShowAlertFournisseurs, setIsShowAlertFournisseurs] =
    useState<boolean>(false)
  const pageIndex = searchParams.get('page-index')
  const pageSize = searchParams.get('page-size')
  const count = Number(searchParams.get('count'))
  const action = searchParams.get('action')
  const isValidAll =
    isValid_identification &&
    isValid_commentaire &&
    isValid_gestion &&
    isValid_packing &&
    isValid_fournisseurs
  const [identificationDataFromApi, setIdentificationDataFromApi] =
    useState<FormStateInterface>()

  const [fournisseurDataFromApi, setFournisseurDataFromApi] = useState<
    DataTableParam[][]
  >([])
  const [gestionDataFromApi, setGestionDataFromApi] =
    useState<FormGestionStateInterface>()
  const [commentaireDataFromApi, setCommentaireDataFromApi] =
    useState<FormCommentaireStateInterface>()
  const [createAt, setCreateAt] = useState<string>('')
  const [updateAt, setUpdateAt] = useState<string>('')
  const [blockDel, setBlockDel] = useState<boolean>(true)
  const [cdnDelId, setCdnDelId] = useState<string[]>([])
  const [fnsDelId, setFnsDelId] = useState<string[]>([])
  const [refId, setRefId] = useState<string>()
  const [isPackingInit, setIsPackingInit] = useState<boolean>(false)

  const [openPopupDelete, setOpenPopupDelete] = useState<boolean>(false)
  const [client, setClient] = useState<string>()
  const [qteVariable, setQteVariable] = useState(false)

  useEffect(() => {
    let errorIdentificationClone = cloneDeep(errorIdentification)
    if (identificationData?.number) {
      errorIdentificationClone = errorIdentificationClone.filter(
        (item) =>
          !(
            item.name === 'number' &&
            (item.errorMessage === 'Numéro déjà existant' ||
              item.errorMessage === ' ')
          )
      )
    }
    if (identificationData?.libelle_long) {
      errorIdentificationClone = errorIdentificationClone.filter(
        (item) => !(item.name === 'libelle_long' && item.errorMessage === ' ')
      )
    }
    setErrorIdentification(errorIdentificationClone)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identificationData])

  useEffect(() => {
    let errorGestionClone = cloneDeep(errorGestion)
    if (gestionData?.dluo_value) {
      errorGestionClone = errorGestionClone.filter(
        (item) => !(item.name === 'dluo_value' && item.errorMessage === ' ')
      )
    }
    if (gestionData?.dlc_value) {
      errorGestionClone = errorGestionClone.filter(
        (item) => !(item.name === 'dlc_value' && item.errorMessage === ' ')
      )
    }
    if (gestionData?.dlv_value) {
      errorGestionClone = errorGestionClone.filter(
        (item) => !(item.name === 'dlv_value' && item.errorMessage === ' ')
      )
    }
    if (!gestionData?.dluo) {
      errorGestionClone = errorGestionClone.filter(
        (item) => !(item.name === 'dluo_value' && item.errorMessage === ' ')
      )
    }
    if (!gestionData?.dlv) {
      errorGestionClone = errorGestionClone.filter(
        (item) => !(item.name === 'dlv_value' && item.errorMessage === ' ')
      )
    }
    if (!gestionData?.dlc) {
      errorGestionClone = errorGestionClone.filter(
        (item) => !(item.name === 'dlc_value' && item.errorMessage === ' ')
      )
    }

    setErrorGestion(errorGestionClone)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gestionData])

  const onChangeIdentification = (values: FormStateInterface) => {
    setIdentificationData(values)
  }
  const onCheckInValidIdentification = (isValid: boolean) => {
    set_isValid_identification(isValid)
  }

  const onCheckValidGestion = (isValid: boolean) => {
    set_isValid_gestion(isValid)
  }

  const onChangeDataCommentaire = (values: FormCommentaireStateInterface) => {
    setCommentaireData(values)
  }

  const onChangeGestion = (values: FormGestionStateInterface) => {
    setGestionData(values)
  }

  const onChangeDataPacking = (data: DataTableParam[][]) => {
    setPackingData(data)
  }
  const onCheckValidPacking = (isValid: boolean) => {
    set_isValid_packing(isValid)
  }

  const onChangeDataFournisseurs = (data: DataTableParam[][]) => {
    setFournisseursData(data)
  }
  const onCheckValidFournisseurs = (isValid: boolean) => {
    set_isValid_fournisseurs(isValid)
  }

  const onCancel = () => {
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1)
    } else {
      if (props.mode === MODE.VIEW) {
        navigate(
          `/gestion-de-stock/attendu?page-index=${pageIndex}&page-size=${pageSize}`
        )
      }
      if (props.mode === MODE.EDIT) {
        dispatch(
          getAllReference({
            limit: Number(pageSize) || 25,
            offset: (Number(pageIndex) - 1) * Number(pageSize) || 0,
          })
        )
        navigate(
          `/configurations/reference?page-index=${pageIndex}&page-size=${pageSize}`
        ) // the current entry in the history stack will be replaced with the new one with { replace: true }
      }
    }
  }

  const onBack = () => {
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1)
    } else {
      navigate(
        `/configurations/reference?page-index=${pageIndex}&page-size=${pageSize}&count=${
          count + 1
        }`
      ) // the current entry in the history stack will be replaced with the new one with { replace: true }
    }
  }

  const onSubmit = () => {
    setNumberOfSubmit(numberOfSubmit + 1)
    let errorIdentificationClone = cloneDeep(errorIdentification)

    if (!identificationData?.number) {
      errorIdentificationClone.push({
        name: 'number',
        errorMessage: ' ',
      })
    } else
      errorIdentificationClone = errorIdentificationClone.filter(
        (item) => item.name !== 'number' && item.errorMessage !== ''
      )

    if (!identificationData?.libelle_long) {
      errorIdentificationClone.push({
        name: 'libelle_long',
        errorMessage: ' ',
      })
    } else
      errorIdentificationClone = errorIdentificationClone.filter(
        (item) => item.name !== 'libelle_long' && item.errorMessage !== ''
      )
    setErrorIdentification(errorIdentificationClone)

    let errorGestionClone = cloneDeep(errorGestion)
    if (!gestionData?.dluo_value && gestionData?.dluo) {
      errorGestionClone.push({
        name: 'dluo_value',
        errorMessage: ' ',
      })
    } else
      errorGestionClone = errorGestionClone.filter(
        (item) => item.name !== 'dluo_value' && item.errorMessage !== ''
      )

    if (!gestionData?.dlc_value && gestionData?.dlc) {
      errorGestionClone.push({
        name: 'dlc_value',
        errorMessage: ' ',
      })
    } else
      errorGestionClone = errorGestionClone.filter(
        (item) => item.name !== 'dlc_value' && item.errorMessage !== ''
      )

    if (!gestionData?.dlv_value && gestionData?.dlv) {
      errorGestionClone.push({
        name: 'dlv_value',
        errorMessage: ' ',
      })
    } else
      errorGestionClone = errorGestionClone.filter(
        (item) => item.name !== 'dlv_value' && item.errorMessage !== ''
      )

    setErrorGestion(errorGestionClone)

    if (!identificationData?.number || !identificationData?.libelle_long) return
    if (!isValidAll) return

    const data: ReferenceRequest = {
      modification_by: localStorage.getItem(Define.USERNAME) || '',
    }
    data.id = params?.id
    data.dluo = gestionData?.dluo || false
    if (gestionData?.dluo_value)
      data.dluo_value = Number(gestionData.dluo_value)
    data.dlv = gestionData?.dlv || false
    if (gestionData?.dlv_value) data.dlv_value = Number(gestionData?.dlv_value)
    data.dlc = gestionData?.dlc || false
    if (gestionData?.dlc_value) data.dlc_value = Number(gestionData?.dlc_value)
    data.gestion = gestionData?.gestion || false
    data.blocage_entree = gestionData?.blocage_entree || false
    data.blocage_sortie = gestionData?.blocage_sortie || false
    if (gestionData?.alerte_stock) data.alerte_stock = gestionData?.alerte_stock
    if (gestionData?.niveau_alerte)
      data.niveau_alerte = gestionData?.niveau_alerte
    data.variante = gestionData?.variante || false

    data.icpe = []
    data.matieresdangereuses = []
    data.photo = ''

    //edit commentaire
    if (
      commentaireData?.com_chargement.comment !==
      commentaireDataFromApi?.com_chargement.comment
    ) {
      data.ref_charement = {
        comment: commentaireData?.com_chargement.comment,
        id: commentaireDataFromApi?.com_chargement.id,
        reference_id: refId,
      }
    }

    if (
      commentaireData?.com_livraison.comment !==
      commentaireDataFromApi?.com_livraison.comment
    ) {
      data.ref_livraison = {
        comment: commentaireData?.com_livraison.comment,
        id: commentaireDataFromApi?.com_livraison.id,
        reference_id: refId,
      }
    }

    if (
      commentaireData?.com_reception.comment !==
      commentaireDataFromApi?.com_reception.comment
    ) {
      data.ref_reception = {
        comment: commentaireData?.com_reception.comment,
        id: commentaireDataFromApi?.com_reception.id,
        reference_id: refId,
      }
    }

    const conditionnement: Conditionnement[] = []
    const ref_fournissers: RefFournisser[] = []
    if (packingData) {
      for (let i = 0; i < packingData.length; i++) {
        const line = packingData[i]
        const id = line.find((item) => item.name === 'id')?.value

        const couche = Number(
          line.find((item) => item.name === 'couche')?.value
        )
        const hauteur = Number(
          line.find((item) => item.name === 'hauteur')?.value || ''
        )
        const icone = line.find((item) => item.name === 'icone')?.value || ''
        const largeur = Number(
          line.find((item) => item.name === 'largeur')?.value || ''
        )
        const longueur = Number(
          line.find((item) => item.name === 'longueur')?.value || ''
        )
        const niveau = Number(
          line.find((item) => item.name === 'niveau')?.value || ''
        )
        const no_codebarre =
          line.find((item) => item.name === 'code_barre')?.value || ''
        const nom = line.find((item) => item.name === 'nom')?.value || ''
        const poids_brut = Number(
          line.find((item) => item.name === 'poids_brut')?.value || ''
        )
        const poids_net = Number(
          line.find((item) => item.name === 'poids_net')?.value || ''
        )
        const poids_add = Number(
          line.find((item) => item.name === 'poids_add')?.value || ''
        )
        const rotation =
          line.find((item) => item.name === 'rotation')?.value || ''
        const support =
          line.find((item) => item.name === 'support')?.value || ''
        const type = Number(
          line.find((item) => item.name === 'type')?.value || ''
        )
        const type_de_codebarre =
          line.find((item) => item.name === 'typeCB')?.value || ''

        const att_enable =
          line.find((item) => item.name === 'att_enable')?.value === '0'
            ? false
            : true

        let mergeData: Conditionnement = {
          couche,
          hauteur,
          icone,
          largeur,
          longueur,
          niveau,
          no_codebarre,
          nom,
          poids_brut,
          poids_net,
          poids_add,
          rotation,
          support,
          type,
          type_de_codebarre,
          att_enable,
          qte_variable: qteVariable,
        }

        if (gestionData?.variante) {
          mergeData.variante =
            line.find((item) => item.name === 'variante')?.value || '00'
        }

        let qte =
          line.find((item) => item.name === 'qte_contenant')?.value || ''
        if (qte) mergeData.qte = Number(qte)
        if (!couche) delete mergeData['couche']
        if (props.mode === MODE.EDIT && id) mergeData.id = id
        conditionnement.push(mergeData)
      }
    }
    data.conditionnement = conditionnement

    data.nom = identificationData?.number.trim()
    data.libelle_long = identificationData?.libelle_long
    data.marque = identificationData?.marque
    data.langua_libelle = identificationData?.langua_libelle
    data.libelle_court = identificationData?.libelle_court
    data.famille = identificationData?.famille
    data.sous_famille = identificationData?.sous_famille
    data.origine = identificationData?.origine
    data.photo = identificationData.photo
    data.qte_variable = qteVariable
    data.modification_by = localStorage.getItem('username') || ''
    data.modificationer_id = localStorage.getItem('user_id') || ''
    data.created_by = localStorage.getItem('username') || ''
    data.creater_id = localStorage.getItem('user_id') || ''
    if (fournisseursData) {
      //set ref fournisseur data send to api if ref fournisseur empty
      let firstLine = !isEmpty(fournisseursData)
        ? fournisseursData[0].find((item) => item.name === 'nom')?.value
        : null
      if (fournisseursData.length === 1 && !firstLine) {
        ref_fournissers.length = 0
      } else
        for (let i = 0; i < fournisseursData.length; i++) {
          const line = fournisseursData[i]

          const id =
            action !== ActionOnReference.duplicate
              ? line.find((item) => item.name === 'id')?.value
              : undefined
          const ref_nom =
            action !== ActionOnReference.duplicate
              ? line.find((item) => item.name === 'ref_nom')?.value
              : data.nom
          const att_enable = line.find(
            (item) => item.name === 'att_enable'
          )?.value
          const fournisser_id = line.find((item) => item.name === 'nom')?.value
          const reference_fournisser =
            line.find((item) => item.name === 'fournisseur')?.value || ''
          const blocage_entree = gestionData?.blocage_entree || false

          let pushData = {
            fournisser_id,
            reference_fournisser,
            blocage_entree,
            client_code_nom: client,
            ref_nom,
            att_enable: Boolean(att_enable),
            id,
          }

          if (props.mode === MODE.EDIT && id) {
            ref_fournissers.push(pushData)
          } else ref_fournissers.push(pushData)
        }
      data.ref_fournissers = ref_fournissers
    }

    data.ref_fournissers =
      ref_fournissers.length > 0 ? ref_fournissers : undefined
    data.client_code_nom =
      client || localStorage.getItem(Define.CHOOSING_CLIENT_CODENOM) || ''

    setIsLoading(true)

    if (action !== ActionOnReference.duplicate && props.mode !== MODE.CREATE) {
      const finalData: UpdateReferenceRequest = {
        conditionnement_delete: {
          id: cdnDelId,
        },
        ref_four_delete: {
          id: fnsDelId,
        },
        entry: data,
      }

      referenceApi
        .updateReference(finalData)
        .then(async (res: any) => {
          await getDetailRef(params?.id || '')

          notification.open({
            className: 'noti noti-success',
            message: (
              <div className="flex items-center">
                <img
                  src={SUCCESS}
                  alt="success"
                  width={50}
                  className="mr-2.5"
                />
                {t('editSuccess')}
              </div>
            ),
            placement: 'topRight',
            closeIcon: <img src={CLOSE_ICON} alt="close" className="ml-28" />,
            duration: 3,
          })

          setErrorIdentification([])
          onCancel()
        })
        .catch((error: any) => {
          if (
            error?.response?.data?.error?.includes(
              'data invalid Nom douplicated'
            )
          ) {
            const errorIdentificationClone = cloneDeep(errorIdentification)
            errorIdentificationClone.push({
              name: 'number',
              errorMessage: 'Numéro déjà existant',
            })
            setErrorIdentification(errorIdentificationClone)
          } else showPopupErrorGeneral()
        })
        .finally(() => {
          setIsLoading(false)
        })
    } else {
      if (commentaireData?.com_chargement.comment) {
        data.ref_charement = commentaireData?.com_chargement
      }
      if (commentaireData?.com_livraison.comment) {
        data.ref_livraison = commentaireData?.com_livraison
      }
      if (commentaireData?.com_reception.comment) {
        data.ref_reception = commentaireData?.com_reception
      }
      delete data.id

      referenceApi
        .createReference(data)
        .then(async (res: any) => {
          notification.open({
            className: 'noti noti-success',
            message: (
              <div className="flex items-center">
                <img
                  src={SUCCESS}
                  alt="success"
                  width={50}
                  className="mr-2.5"
                />
                {props.mode === MODE.EDIT
                  ? t('editSuccess')
                  : 'Succès: Référence enregistrée'}
              </div>
            ),
            placement: 'topRight',
            closeIcon: <img src={CLOSE_ICON} alt="close" className="ml-28" />,
            duration: 3,
          })

          setErrorIdentification([])
          onCancel()
        })
        .catch((error: any) => {
          if (
            error?.response?.data?.error?.includes('invalid Nom douplicated')
          ) {
            const errorIdentificationClone = cloneDeep(errorIdentification)
            errorIdentificationClone.push({
              name: 'number',
              errorMessage: 'Numéro déjà existant',
            })
            setErrorIdentification(errorIdentificationClone)
          } else showPopupErrorGeneral()
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  const showPopupErrorGeneral = () => {
    notification.open({
      className: 'noti noti-error',
      message: (
        <div className="flex items-center">
          <img src={ERROR} alt="error" width={50} className="mr-2.5" />
          {t('popupErrorGeneral')}
        </div>
      ),
      placement: 'topRight',
      closeIcon: <img src={CLOSE_ICON} alt="close" className="ml-28" />,
      duration: 3,
    })
  }

  useEffect(() => {
    const id = params?.id
    if (!id) return
    getDetailRef(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!isValid_packing && numberOfSubmit > 0) {
      setIsShowAlertPacking(true)
    }
    if (isValid_packing && numberOfSubmit > 0) {
      setIsShowAlertPacking(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberOfSubmit])

  useEffect(() => {
    if (!isValid_fournisseurs && numberOfSubmit > 0) {
      setIsShowAlertFournisseurs(true)
    }
    if (isValid_fournisseurs && numberOfSubmit > 0) {
      setIsShowAlertFournisseurs(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberOfSubmit])

  async function getDetailRef(id: string) {
    setIsLoading(true)
    const reference = await referenceApi.getReference(id)

    let entry = reference.data.entry
    setQteVariable(Boolean(entry.qte_variable))
    setCreateAt(moment((entry.create_at || 0) * 1000).format(t('time-format')))
    setUpdateAt(moment((entry.update_at || 0) * 1000).format(t('time-format')))

    setCdnDelId([])
    setFnsDelId([])

    setRefId(entry.id)
    setClient(entry.client_code_nom)

    setIdentificationDataFromApi({
      number: action === ActionOnReference.duplicate ? '' : entry.nom || '',
      libelle_long: entry.libelle_long || '',
      libelle_court: entry.libelle_court || '',
      langua_libelle: entry.langua_libelle || '',
      marque: entry.marque || '',
      origine: entry.origine || '',
      sous_famille: entry.sous_famille || '',
      famille: entry.famille || '',
      photo: action === ActionOnReference.duplicate ? '' : entry.photo || '',
      client: entry.client_code_nom,
    })

    setGestionDataFromApi({
      dluo: entry.dluo || false,
      dluo_value: entry.dluo_value || 0,
      dlv: entry.dlv || false,
      dlv_value: entry.dlv_value || 0,
      dlc: entry.dlc || false,
      dlc_value: entry.dlc_value || 0,
      gestion: entry.gestion || false,
      blocage_entree: entry.blocage_entree || false,
      blocage_sortie: entry.blocage_sortie || false,
      alerte_stock: entry.alerte_stock || 0,
      niveau_alerte: entry.niveau_alerte || '',
      variante: entry.variante || false,
    })

    setCommentaireDataFromApi({
      com_chargement: {
        comment: entry.ref_charement?.comment || '',
        id: entry.ref_charement?.id,
      },

      com_livraison: {
        comment: entry.ref_livraison?.comment || '',
        id: entry.ref_livraison && entry.ref_livraison.id,
      },
      com_reception: {
        comment: entry.ref_reception?.comment || '',
        id: entry.ref_reception && entry.ref_reception.id,
      },
    })

    let fns: DataTableParam[][] = []
    let fnsEntry = entry.ref_fournissers

    if (fnsEntry) {
      fnsEntry = fnsEntry.map((item) => {
        if (!item.reference_fournisser) item.reference_fournisser = ''
        return item
      })
      for (let i = 0; i < fnsEntry.length; i++) {
        let currentLineData: DataTableParam[] = []
        for (let j = 0; j < Object.keys(fnsEntry[i]).length; j++) {
          let name = Object.keys(fnsEntry[i])[j]
          let value = Object.values(fnsEntry[i])[j]
            ? Object.values(fnsEntry[i])[j].toString()
            : ''
          if (name === 'fournisser_id') name = 'nom'
          if (name === 'reference_fournisser') name = 'fournisseur'
          if (action === ActionOnReference.duplicate) {
            if (name === 'fournisseur') value = ''
          }
          currentLineData.push({ name, value })
        }
        currentLineData.push({ name: 'uuid', value: randomID('fns-line') })
        fns.push(currentLineData)
      }
    }

    setFournisseursData(fns)
    setFournisseurDataFromApi(fns)

    const cdnData: DataTableParam[][] = []
    const conditionement = entry.conditionnement
    if (conditionement) {
      for (let i = 0; i < conditionement.length; i++) {
        const currentCdn = conditionement[i]
        const currentLineData: DataTableParam[] = []
        for (const key in currentCdn) {
          let name = key
          let value = currentCdn[key as keyof Conditionnement]?.toString()
          let unit
          if (name === 'no_codebarre') name = 'code_barre'
          if (name === 'type_de_codebarre') name = 'typeCB'
          if (['poids_net', 'poids_brut', 'poids_add'].includes(name))
            unit = Unit.KG
          if (['longueur', 'largeur', 'hauteur'].includes(name)) unit = Unit.CM
          currentLineData.push({ name, value, unit })
        }
        if (!('att_enable' in currentCdn))
          currentLineData.push({ name: 'att_enable', value: '0' })
        if (!('poids_add' in currentCdn))
          currentLineData.push({ name: 'poids_add', value: '0.000' })
        if (!('support' in currentCdn))
          currentLineData.push({ name: 'support', value: '' })
        if (!('no_codebarre' in currentCdn))
          currentLineData.push({ name: 'code_barre', value: '' })
        if (!('couche' in currentCdn))
          currentLineData.push({ name: 'couche', value: '' })

        cdnData.push(currentLineData)
      }
    }

    setBlockDel(entry?.block_del || false)
    setPackingDataFromApi(cdnData)
    setPackingData(cdnData)
    setIsLoading(false)
  }

  const onDeleteRef = () => {
    const id = params?.id
    if (!id) return
    setIsLoading(true)
    referenceApi
      .deleteById(id)
      .then((res) => {
        onBack()
      })
      .catch((err) => {
        showPopupErrorGeneral()
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const onDeleteLinePacking = (id: string) => {
    if (!id) return
    let cdnDelIdClone = cloneDeep(cdnDelId)
    cdnDelIdClone = cdnDelIdClone.filter((item) => item !== id)
    cdnDelIdClone.push(id)
    setCdnDelId(cdnDelIdClone)
  }

  const onDeleteLineFns = (id: string) => {
    if (!id) return
    let fndDelIdClone = cloneDeep(fnsDelId)
    fndDelIdClone = fndDelIdClone.filter((item) => item !== id)
    fndDelIdClone.push(id)
    setFnsDelId(fndDelIdClone)
  }

  const onBackToInitPacking = (isInit: boolean) => {
    setIsPackingInit(isInit)
  }

  const onBackReferenceScreen = () => {
    navigate(
      `/configurations/reference?page-index=${pageIndex}&page-size=${pageSize}`
    )
  }

  const onChangeQteVar = (isUseQteVar: boolean) => {
    setQteVariable(isUseQteVar)
  }

  if (props.mode === MODE.VIEW)
    return (
      <Modal
        prefixCls={
          props.mode !== MODE.VIEW
            ? `modal-new-stock`
            : 'modal-new-stock-view-only'
        }
        centered
        open={true}
        onOk={() => false}
        onCancel={onCancel}
        width={'90%'}
        className="border-solid border-secondary rounded-xl"
        footer={null}
        destroyOnClose={true}
      >
        <>
          <CustomPopup
            title="Confirmation de la suppression?"
            type={popupType.DELETE}
            isOpen={openPopupDelete}
            onConfirm={onDeleteRef}
            onClose={() => setOpenPopupDelete(false)}
          >
            <p className="text-lg text-[#505050] my-10">
              Êtes-vous sûr de vouloir supprimer cette référence?
            </p>
          </CustomPopup>
          <Spin spinning={isLoading} style={{ overflow: 'auto' }}>
            <Row className={`bd-bot-page-title pb-2`}>
              <Col className="flex items-baseline">
                <span className="modal-title-heading-1 mr-4">Référence</span>
                <span className="text-[#20458F] text-2xl">{`(n° ${identificationData?.number})`}</span>
              </Col>
            </Row>
            <div
              className={`${
                props.mode === MODE.VIEW
                  ? 'overflow-y-auto main-content'
                  : 'create-section'
              }`}
            >
              {action !== ActionOnReference.duplicate && (
                <>
                  <div className="flex justify-between italic text-[#808080] mt-1">
                    <div className="text-[#848484]">Création : {createAt}</div>
                    <div className="text-[#848484]">
                      Modification : {updateAt}
                    </div>
                  </div>
                  <div className="h-4"></div>
                </>
              )}

              <div className="gap-20"></div>
              <Identification
                action={action as ActionOnReference}
                mode={props.mode}
                onChangeData={onChangeIdentification}
                onCheckValid={onCheckInValidIdentification}
                identificationError={errorIdentification}
                initData={identificationDataFromApi}
              />
              <Packing
                qteVariable={qteVariable}
                mode={props.mode}
                initData={packingDataFromApi}
                onChangeData={onChangeDataPacking}
                onCheckValid={onCheckValidPacking}
                onDeleteLine={onDeleteLinePacking}
                onBackToInit={onBackToInitPacking}
                onChangeQteVar={onChangeQteVar}
                alert={
                  isShowAlertPacking && !isValid_packing ? (
                    !isPackingInit ? (
                      alertNode('Veuillez valider toutes ces lignes suivantes')
                    ) : (
                      alertNode('Veuillez ajouter un Conditionnement')
                    )
                  ) : (
                    <></>
                  )
                }
                enableVariante={gestionData?.variante}
              />
              <Fournisseurs
                mode={props.mode}
                action={props.action}
                onChangeData={onChangeDataFournisseurs}
                onCheckValid={onCheckValidFournisseurs}
                onDeleteLine={onDeleteLineFns}
                alert={
                  isShowAlertFournisseurs && !isValid_fournisseurs ? (
                    alertNode('Veuillez valider toutes ces lignes suivantes')
                  ) : (
                    <></>
                  )
                }
                initData={fournisseurDataFromApi}
                clientCodeNom={client}
                detailLength={packingData?.length || 0}
              />
              <Gestion
                mode={props.mode}
                onChangeData={onChangeGestion}
                onCheckValid={onCheckValidGestion}
                gestionError={errorGestion}
                initData={gestionDataFromApi}
              />
              <Commentaire
                mode={props.mode}
                onChangeData={onChangeDataCommentaire}
                initData={commentaireDataFromApi}
              />
            </div>

            {props.mode !== MODE.VIEW && (
              <div
                className={`${
                  props.mode === MODE.EDIT &&
                  'fixed-bottom bg-white create-section p-3.5'
                } footer-content flex items-center z-20`}
              >
                <Row
                  align="middle"
                  justify={
                    blockDel || action === ActionOnReference.duplicate
                      ? 'end'
                      : 'space-between'
                  }
                  className="w-full"
                >
                  {!blockDel && action !== ActionOnReference.duplicate && (
                    <Col>
                      <button
                        id={stockKey['Supprimer']}
                        data-next-id={stockKey['Sauvegarder']}
                        data-previous-id={stockKey['Réception']}
                        tabIndex={0}
                        onClick={() => setOpenPopupDelete(true)}
                        className="supprimer border-red border-solid bg-white hover:text-red text-red text-lg font-semibold pl-12 pr-5 py-1 rounded-lg cursor-pointer mr-4 relative"
                      >
                        {t('deletion')}
                      </button>
                    </Col>
                  )}
                  <Col>
                    <div
                      id={stockKey['Sauvegarder']}
                      data-previous-id={stockKey['Supprimer']}
                      data-next-id={stockKey['Langue-libellé']}
                      tabIndex={0}
                      role="button"
                      className={`btn-submit-modal   text-btn-submit-last-modal `}
                      onClick={onSubmit}
                    >
                      {t('save')}
                    </div>
                  </Col>
                </Row>
              </div>
            )}
          </Spin>
        </>
      </Modal>
    )

  return (
    <>
      <CustomPopup
        title="Confirmation de la suppression?"
        type={popupType.DELETE}
        isOpen={openPopupDelete}
        onConfirm={onDeleteRef}
        onClose={() => setOpenPopupDelete(false)}
      >
        <p className="text-lg text-[#505050] my-10">
          Êtes-vous sûr de vouloir supprimer cette référence?
        </p>
      </CustomPopup>
      <Spin spinning={isLoading} style={{ overflow: 'auto' }}>
        <Row
          className={`${
            (props.mode === MODE.EDIT || props.mode === MODE.CREATE) &&
            'create-section'
          } bd-bot-page-title pb-2`}
        >
          <Col className="flex items-baseline">
            {(props.mode === MODE.EDIT || props.mode === MODE.CREATE) && (
              <ArrowLeftOutlined
                className="text-primary text-2xl mr-3"
                onClick={onBackReferenceScreen}
              />
            )}
            {props.mode !== MODE.CREATE ? (
              <>
                <span className="modal-title-heading-1 mr-4">Référence</span>
                <span className="text-[#20458F] text-2xl">{`(n° ${identificationData?.number})`}</span>
              </>
            ) : (
              <>
                <span className="modal-title-heading-1 mr-4">Création</span>
                <span className="text-[#20458F] text-2xl">Référence</span>
              </>
            )}
          </Col>
        </Row>
        <div className={'create-section'}>
          {action !== ActionOnReference.duplicate &&
            props.mode !== MODE.CREATE && (
              <>
                <div className="flex justify-between italic text-[#808080] mt-1">
                  <div className="text-[#848484]">Création : {createAt}</div>
                  <div className="text-[#848484]">
                    Modification : {updateAt}
                  </div>
                </div>
                <div className="h-4"></div>
              </>
            )}

          <div className="gap-20"></div>
          <Identification
            action={action as ActionOnReference}
            mode={props.mode}
            onChangeData={onChangeIdentification}
            onCheckValid={onCheckInValidIdentification}
            identificationError={errorIdentification}
            initData={identificationDataFromApi}
          />
          <Packing
            qteVariable={qteVariable}
            mode={props.mode}
            initData={
              packingDataFromApi.length === 0 ? undefined : packingDataFromApi
            }
            onChangeData={onChangeDataPacking}
            onCheckValid={onCheckValidPacking}
            onDeleteLine={onDeleteLinePacking}
            onBackToInit={onBackToInitPacking}
            onChangeQteVar={onChangeQteVar}
            alert={
              isShowAlertPacking && !isValid_packing ? (
                !isPackingInit ? (
                  alertNode('Veuillez valider toutes ces lignes suivantes')
                ) : (
                  alertNode('Veuillez ajouter un Conditionnement')
                )
              ) : (
                <></>
              )
            }
            enableVariante={gestionData?.variante}
          />
          <Fournisseurs
            mode={props.mode}
            action={props.action}
            onChangeData={onChangeDataFournisseurs}
            onCheckValid={onCheckValidFournisseurs}
            onDeleteLine={onDeleteLineFns}
            alert={
              isShowAlertFournisseurs && !isValid_fournisseurs ? (
                alertNode('Veuillez valider toutes ces lignes suivantes')
              ) : (
                <></>
              )
            }
            initData={fournisseurDataFromApi}
            clientCodeNom={client}
            detailLength={packingData?.length || 0}
          />
          <Gestion
            mode={props.mode}
            onChangeData={onChangeGestion}
            onCheckValid={onCheckValidGestion}
            gestionError={errorGestion}
            initData={gestionDataFromApi}
          />
          <Commentaire
            mode={props.mode}
            onChangeData={onChangeDataCommentaire}
            initData={commentaireDataFromApi}
          />
        </div>

        <div
          className={`${
            (props.mode === MODE.EDIT || props.mode === MODE.CREATE) &&
            'fixed-bottom bg-white create-section p-3.5'
          } footer-content flex items-center z-20`}
        >
          <Row
            align="middle"
            justify={
              blockDel || action === ActionOnReference.duplicate
                ? 'end'
                : 'space-between'
            }
            className="w-full"
          >
            {!blockDel && action !== ActionOnReference.duplicate && (
              <Col>
                <button
                  id={stockKey['Supprimer']}
                  data-next-id={stockKey['Sauvegarder']}
                  data-previous-id={stockKey['Réception']}
                  tabIndex={0}
                  onClick={() => setOpenPopupDelete(true)}
                  className="supprimer border-red border-solid bg-white hover:text-red text-red text-lg font-semibold pl-12 pr-5 py-1 rounded-lg cursor-pointer mr-4 relative"
                >
                  {t('deletion')}
                </button>
              </Col>
            )}
            <Col>
              <div
                id={stockKey['Sauvegarder']}
                data-previous-id={stockKey['Supprimer']}
                data-next-id={stockKey['Langue-libellé']}
                tabIndex={0}
                role="button"
                className={`btn-submit-modal   text-btn-submit-last-modal `}
                onClick={onSubmit}
              >
                {t('save')}
              </div>
            </Col>
          </Row>
        </div>
      </Spin>
    </>
  )
}

const alertNode = (text: string): ReactNode => (
  <span
    style={{ fontSize: 12, color: 'red', marginLeft: 5 }}
  >{`(${text})`}</span>
)
